.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.25);
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 240px;
  background-color: #191a23;
  border-radius: 4px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 8px 0px #000;
}

.looserIcon,
.winnerIcon {
  width: 60px;
  margin-bottom: 16px;
}

.winnerIcon {
  color: #ffc21a;
}

.looserIcon {
  color: #dc494d;
}

.title {
  margin: 0 0 32px 0;
}

.returnLink {
  text-decoration: none;
  width: 160px;
  padding: 8px;
  border: 1px solid #fff;
  color: #fff;
  text-align: center;
  border-radius: 40px;
}
