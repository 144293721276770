.actionDialog {
  border: 2px solid #27292c;

  &.top {
    top: 100%;
    margin-top: 18px;

    &:before {
      bottom: 100%;
      border-bottom-color: white;
    }
  }

  &.bottom {
    bottom: 100%;
    margin-bottom: 18px;

    &:before {
      top: 100%;
      border-top-color: white;
    }
  }

  &:before {
    content: '';
    width: 0;
    margin-right: auto;
    margin-left: auto;
    border: 6px solid transparent;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}