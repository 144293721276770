.loading {
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    margin-bottom: 12px;
  }
}

.playerAvatar {
  width: 1.75rem;
  height: 1.75rem;
}
