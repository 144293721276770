.actionsBar {
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.25);
}

.actionButton {
  & + & {
    border-left: 1px solid #1A1A1A;
  }
}

.actionButtonBg {
  background-color: #333;

  &:disabled {
    color: #808080;
    cursor: not-allowed;
  }

  &:not(:disabled):hover, &:not(:disabled):focus {
    background-color: #595959;
  }
}