/* TODO: Fix @apply in CRA with css modules */

input:checked + .radioLabel {
    background-color: #ebf8ff;
    border: 1px solid #4299e1;

    .iconContainer {
        background-color: #4299e1;
    }

    .radioIcon {
        display: inline-block;
    }
}

input:focus + .radioLabel {
    border: 1px solid #90cdf4;
}

.comingSoon {
    font-size: 0.625rem;
    padding-top: 2px;
    padding-bottom: 2px;
}

.modal {
    outline: none;
    box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.2);
}

.modalOverlay {
    background-color: rgba(0, 0, 0, 0.75);
}