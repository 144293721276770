.login {
  height: 100%;
}

.listItem {
  // Fix text-shadow not working https://github.com/FortAwesome/Font-Awesome/issues/11916
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
}

.facebookBtn {
  background-color: #4267B2;

  &:hover,
  &:focus {
    background-color: #6383c5;
  }
}

.googleBtn {
  background-color: #DB4437;

  &:hover,
  &:focus {
    background-color: #e36e64;
  }
}